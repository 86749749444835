 
.container-profile > .card {
    cursor: pointer; 
    transform: scale(1);
    transition: all 1s; 
}

.container-profile:hover > .card {
    transform: scale(1.1);
}
/*
.container:hover > .card{
    cursor: pointer; 
    transform: rotateY(180deg);
} */
